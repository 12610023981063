.services {
}

.awesome-service {
  display: flex;
  flex-direction: column;
  position: relative;
}
.awesome-service > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome-service > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome-service > :nth-child(3) {
  font-size: 14px;
  margin-top: 1rem;
}

/* right sided */
.cards {
  position: relative;
}
.cards > * {
  position: absolute;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width: 480px) {
  .cards {
    position: inherit;
  }
  .cards > * {
    position: static;
    z-index: 1;
  }
  .services {
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .cards:nth-child(1) {
    left: -5% !important;
  }
}
