.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}

.copyright {
  right: 10%;
  bottom: 10%;
  color: white;
}
